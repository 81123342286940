import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import { useFormik, FormikProvider } from "formik";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "../../styles/style.css";
import { useAuthContext } from "../../../providers/ApplicationProvider";
import Navmenu from "../../layout/NavMenuAll";
import AccessDenied from "../../Pages/AccessDenied";

const validate = (values) => {
  const errors = {};
  if (!values.Name) errors.Name = "Napiš jméno alba";
  return errors;
};

const ParaAlbumUpload = () => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [{ role }] = useAuthContext();

  const url = "https://localhost:44425/admin/album/list";

  const formik = useFormik({
    initialValues: {
      Name: "",
      Uploader: role,
    },
    validate: validate,
    onSubmit: (values) => {
      var data = JSON.stringify(values);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/Album`,
          { payload: data },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push("/profile/album/list");
        })
        .catch((err) => {});
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/PagesData/sendEmailAlbum`,
          {
            FullName: values.Name,
            Text: "https://localhost:44425/admin/album/list",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push("/profile/album/list");
        })
        .catch((err) => {});
    },
  });

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    return (
      <div>
        <Navmenu />
        <div className="bg-white" style={{ height: "100vh" }}>
          <Container className="col-sm-6">
            <div className="text-center">
              <Button
                className="btn-primary mt-3 mb-3 mx-3"
                tag={Link}
                to="/profile/album/list"
              >
                Zpět na výpis alb
              </Button>
            </div>
            <FormikProvider value={formik}>
              <Card className="border-0 shadow-lg p-3 mb-5 bg-dark rounded">
                <CardBody className="bg-dark text-light">
                  <Form onSubmit={formik.handleSubmit}>
                    <FormGroup className="m-2">
                      <Label for="Name">Název alba</Label>
                      <Input
                        name="Name"
                        id="Name"
                        placeholder="Název alba"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Name}
                        invalid={Boolean(formik.errors.Name)}
                        valid={formik.touched.Name}
                      />
                    </FormGroup>
                    <div>
                      <Button type="submit" className="m-2" color="success">
                        Uložit
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </FormikProvider>
          </Container>
        </div>
      </div>
    );
  }
};

export default ParaAlbumUpload;
