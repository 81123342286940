import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, Label, Card, CardBody, Container } from "reactstrap";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../styles/style.css";
import { useAuthContext } from "../../../providers/ApplicationProvider";
import Navmenu from "../../layout/NavMenuAll";
import AccessDenied from "../../Pages/AccessDenied";

const validate = (values) => {
  const errors = {};
  if (!values.Name) errors.Name = "Napiš jméno alba";
  return errors;
};

const ParaAlbumEditName = () => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [{ role }] = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const [error, setError] = useState(false);
  const { id } = useParams();

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data.name);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function sendData() {
    const values = {
      Id: id,
      Name: response,
    };
    var data = JSON.stringify(values);
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Album`,
        {
          payload: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        history.push(`/profile/album/list`);
      });
  }

  const handleChangeName = (e) => {
    e.preventDefault();
    setResponse(e.target.value);
  };

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    return (
      <div className="bg-white" style={{height: "100vh" }}>
        <Navmenu />
        <Container className="col-sm-6">
          <div className="text-center">
            <Button
              className="btn-primary mt-3 mb-3 mx-3"
              tag={Link}
              to="/admin/album/list"
            >
              Zpět na výpis alb
            </Button>
          </div>
          <Card className="border-0 shadow-lg p-3 mb-5 bg-dark rounded">
            <CardBody className="bg-dark text-light">
              <Label className="text-white mt-1">Název alba</Label>
              <Input
                className="bg-light col-xs-2"
                onChange={handleChangeName}
                value={response}
              />
              <div>
                <Button
                  type="submit"
                  className="mt-3 mb-3"
                  color="success"
                  onClick={() => sendData()}
                >
                  Uložit
                </Button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
};

export default ParaAlbumEditName;
