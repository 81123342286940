import React, { useCallback, useEffect, useState, useContext } from "react";
import { Alert, Button, Container, Table } from "reactstrap";
import axios from "axios";
import Navmenu from "../Navmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPhotoVideo,
  faTimes,
  faTrash,
  faCheck,
  faFileSignature
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Loading from "../../Pages/Loading";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const AlbumList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [indexItem, setIndexItem] = useState();
  const [item, setItem] = useState();
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function setActiveAlbum(id, check) {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Album/setActive`,
        { Id: id, isActive: check },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .catch((err) => {
        setError(true);
      })
      .then(() => {
        window.location.reload(true);
      });
  }

  function renderProjects() {
    function renderCheck(check, id) {
      if (check) {
        return (
          <>
            <Button color="success" refresh="true" onClick={() => setActiveAlbum(id, false)}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
      if (!check) {
        return (
          <>
            <Button color="secondary" refresh="true" onClick={() => setActiveAlbum(id, true)}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
    }
    const array = response.map((item, index) => {
      return (
        <tr key={item.id} className="text-white align-middle">
          <td><Button size="sm" className="me-3" color="success" tag={Link} to={`/admin/album/edit/name/${item.id}`}><FontAwesomeIcon icon={faPencilAlt} size="sm" /></Button> {item.name}</td>
          <td>{renderCheck(item.isActive, item.id)}</td>
          <td>
            <Button
              color="warning"
              refresh="true"
              tag={Link}
              to={`/admin/image/list/${item.id}`}
            >
              <FontAwesomeIcon icon={faPhotoVideo} className="fs-6" />
            </Button>
          </td>
          <td>
            <Button
              color="success"
              refresh="true"
              tag={Link}
              to={`/admin/album/edit/${item.id}`}
            >
              <FontAwesomeIcon icon={faFileSignature} className="fs-6" />
            </Button>
          </td>
          <td>
            <Button
              color="danger"
              refresh="true"
              onClick={() => {
                handleClickOpen();
                setItem(item.id);
                setIndexItem(index);
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="fs-6" />
            </Button>
          </td>
        </tr>
      );
    });
    return array;
  }

  const removeItem = (index) => {
    response.splice(index, 1);
    setResponse([...response]);
  };

  function deleteProject() {
    setIsLoading(true);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Album/delete/${item}`)
      .then(() => {
        removeItem(indexItem);
        setDone(true);
      })
      .catch((error) => {
        setError(true);
        setVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return response;
  }

  function renderSuccess() {
    if (done) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="success" className="mt-3">
            Album odstraněno
            <Button
              className="mx-3"
              size="sm"
              color="success"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  function renderError() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Došlo k chybě! Zkuste to znovu nebo kontaktujte správce.
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (response) {
      return (
        <>
          <Navmenu />
          <Container className="col-sm-12">
            {renderSuccess()}
            {renderError()}
            <Button
              className="mt-3 btn-success"
              tag={Link}
              to="/admin/album/upload"
            >
              Přidat album
            </Button>
            <Button
              className="mt-3 mx-2 btn-success"
              tag={Link}
              to="/admin/album/sort"
            >
              Seřadit alba
            </Button>
            <Table responsive className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded">
              <thead>
                <tr>
                  <th>Název</th>
                  <th>Schválené?</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderProjects()}</tbody>
            </Table>
          </Container>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Potvrďte, že chcete smazat toto album. Po smazání alba už
                to nejde vzít zpět!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Zrušit
              </Button>
              <Button
                onClick={() => {
                  deleteProject();
                  handleClose();
                }}
                color="danger"
              >
                Potvrdit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default AlbumList;