import React, { useContext, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  CardTitle,
  Button,
  Container,
  CardBody,
} from "reactstrap";
import "../admin.css";
import Navmenu from "../Navmenu";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import jwt_decode from "jwt-decode";

const PasswordChangeList = () => {
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);

  var decoded = jwt_decode(accessToken);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
  }, []);
  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    return (
      <>
        <Navmenu />
        <Container className="text-center">
          <h1 className="text-light display-1 mt-3">Vyber účet, kde změnit heslo</h1>
          <Row className="mt-5">
            <Col md className="my-1">
              <Card
                body
                inverse
                className="h-100"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2" style={{textTransform: "uppercase"}}>{decoded.given_name}</CardTitle>
                <CardBody className="d-flex flex-column">
                  <Button tag={Link} to={`/password/change/${decoded.given_name}`}>
                    Změnit heslo
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md className="my-1">
              <Card
                body
                inverse
                className="h-100"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">PARA</CardTitle>
                <CardBody className="d-flex flex-column">
                  <Button tag={Link} to="/password/change/paraklub">
                    Změnit heslo
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default PasswordChangeList;
