import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import { useFormik, FormikProvider } from "formik";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "../../styles/style.css";
import {
  useAuthContext,
} from "../../../providers/ApplicationProvider";
import Navmenu from "../Navmenu";
import AccessDenied from "../../Pages/AccessDenied";

const validate = (values) => {
  const errors = {};
  if (!values.Name) errors.Name = "Napiš jméno akce";
  if (!values.Date) errors.Date = "Vyplň datum akce";
  return errors;
};

const EventUpload = () => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [{ role }] = useAuthContext();

  const formik = useFormik({
    initialValues: {
      Name: "",
      Date: null
    },
    validate: validate,
    onSubmit: (values) => {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/Event`,
          { Name: values.Name, Date: values.Date },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push("/admin/event/list");
        })
        .catch((err) => {
        });
    },
  });

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    return (
      <div>
        <Navmenu />
        <Container className="col-md-6">
          <div className="text-center">
            <Button
              className="btn-primary mt-3 mb-3 mx-3"
              tag={Link}
              to="/admin/event/list"
            >
              Zpět na výpis akcí
            </Button>
          </div>
          <FormikProvider value={formik}>
            <Card className="border-0 shadow-lg p-3 mb-5 bg-dark rounded">
              <CardBody className="bg-dark text-light">
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup className="m-2">
                    <Label for="Name">Název akce (čas)</Label>
                    <Input
                      name="Name"
                      id="Name"
                      placeholder="Název akce (čas)"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name}
                      invalid={Boolean(formik.errors.Name)}
                      valid={formik.touched.Name}
                    />
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="Date">Datum akce</Label>
                    <Input
                      name="Date"
                      id="Date"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Date}
                      invalid={Boolean(formik.errors.Date)}
                      valid={formik.touched.Date}
                    />
                  </FormGroup>
                  <div>
                    <Button type="submit" className="m-2" color="success">
                      Uložit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </FormikProvider>
        </Container>
      </div>
    );
  }
};

export default EventUpload;