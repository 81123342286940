import React, { useCallback, useEffect, useState, useContext } from "react";
import { Button, Container, Table } from "reactstrap";
import axios from "axios";
import Navmenu from "../../layout/NavMenuAll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPhotoVideo,
  faCheck,
  faFileSignature
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Loading from "../../Pages/Loading";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";

const ParaAlbumList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [{ accessToken }] = useContext(ApplicationContext);

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/para`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderProjects() {
    function renderCheck(check) {
      if (check) {
        return (
          <>
            <Button color="success" refresh="true" disabled={check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
      if (!check) {
        return (
          <>
            <Button color="secondary" refresh="true" disabled={!check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
    }
    const array = response.map((item, index) => {
      return (
        <tr key={item.id} className="text-white align-middle">
        <td><Button size="sm" className="me-3" color="success" tag={Link} to={`/profile/album/edit/name/${item.id}`}><FontAwesomeIcon icon={faPencilAlt} size="sm" /></Button> {item.name}</td>          <td>{renderCheck(item.isActive)}</td>
          <td>
            <Button
              color="warning"
              refresh="true"
              tag={Link}
              to={`/profile/image/list/${item.id}`}
            >
              <FontAwesomeIcon icon={faPhotoVideo} className="fs-6" />
            </Button>
          </td>
          <td>
            <Button
              color="success"
              refresh="true"
              tag={Link}
              to={`/profile/album/edit/${item.id}`}
            >
              <FontAwesomeIcon icon={faFileSignature} className="fs-6" />
            </Button>
          </td>
        </tr>
      );
    });
    return array;
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (response) {
      return (
        <>
          <Navmenu />
          <div className="bg-white" style={{height: "100vh"}}>
          <Container className="col-sm-6">
            <Button
              className="mt-3 btn-success me-2"
              tag={Link}
              to="/profile/album/upload"
            >
              Přidat album
            </Button>
            <Button
              className="mt-3 btn-primary"
              tag={Link}
              to="/profile/list"
            >
              Zpět na stránku pro členy
            </Button>
            <p className="my-2 fs-4">Zde jsou pouze neschválené alba, adminovi bude po přidání alba poslán e-mail pro schválení.</p>
            <Table responsive className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded">
              <thead>
                <tr>
                  <th>Název</th>
                  <th>Schválené?</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderProjects()}</tbody>
            </Table>
          </Container>
          </div>
        </>
      );
    }
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default ParaAlbumList;