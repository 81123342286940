import React from 'react';
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Route, Switch } from "react-router";
import { ApplicationProvider } from './providers/ApplicationProvider';
import Home from './components/Home';
import LoginPage from './components/Admin/Auth/LoginPage';
import ChangePassword from './components/Admin/Auth/ChangePassword';
import ForgotPassword from './components/Admin/Auth/ForgotPassword';
import ProfileList from './components/Admin/ProfileList';
import Osvedceni from './components/Pages';
import ResetPassword from './components/Admin/Auth/ResetPassword';
import AccessDenied from './components/Pages/AccessDenied';
import NotFound from './components/Pages/NotFound';
import PagesEdit from './components/Admin/PagesEdit';
import AdminList from './components/Admin/AdminList';
import PagesList from './components/Admin/PagesList';
import Contact from './components/Contact';
import Application from './components/Application';
import AlbumView from './components/AlbumView';
import Gallery from './components/Gallery';
import ImageUpload from './components/Admin/Images/ImageUploader';
import ImageEdit from './components/Admin/Images/ImageEdit';
import ImageList from './components/Admin/Images/ImageList';
import ImageSort from './components/Admin/Images/SortImage';
import DocumentList from './components/Admin/Documents/DocumentList';
import DocumentUpload from './components/Admin/Documents/DocumentUpload';
import AlbumList from './components/Admin/Gallery/AlbumList';
import AlbumEdit from './components/Admin/Gallery/AlbumEdit';
import AlbumSort from './components/Admin/Gallery/AlbumSort';
import AlbumUpload from './components/Admin/Gallery/AlbumUpload';
import DocumentSort from './components/Admin/Documents/DocumentSort';
import DocumentEdit from './components/Admin/Documents/DocumentEdit';
import DocumentParaList from './components/Admin/DocumentParaList';
import EventUpload from './components/Admin/Event/EventUpload';
import EventEdit from './components/Admin/Event/EventEdit';
import EventList from './components/Admin/Event/EventList';
import PasswordChangeList from './components/Admin/Auth/PasswordChangeList';
import ParaAlbumList from './components/Admin/ParaAlbum/ParaAlbumList';
import ParaAlbumEdit from './components/Admin/ParaAlbum/ParaAlbumEdit';
import ParaAlbumUpload from './components/Admin/ParaAlbum/ParaAlbumUpload';
import ParaImageEdit from './components/Admin/ParaAlbum/ParaImageEdit';
import ParaImageList from './components/Admin/ParaAlbum/ParaImageList';
import ParaImageUpload from './components/Admin/ParaAlbum/ParaImageUpload';
import ParaSortImage from './components/Admin/ParaAlbum/ParaSortImage';
import AlbumEditName from './components/Admin/Gallery/AlbumEditName';
import ParaAlbumEditName from './components/Admin/ParaAlbum/ParaAlbumEditName';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css'


function App() {
  const history = useHistory();

  return (
    <div className="App">
      <ApplicationProvider>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/:stranka" component={Osvedceni} />
            <Route exact path="/kontakt/info" component={Contact} />
            <Route exact path="/prihlaska/form" component={Application} />
            <Route exact path="/galerie/list" component={Gallery} />
            <Route exact path="/album/view/:id" component={AlbumView} />
            <Route exact path="/para/login" component={LoginPage} />
            <Route exact path="/profile/list" component={ProfileList} />
            <Route exact path="/profile/document/list" component={DocumentParaList} />
            <Route exact path="/profile/album/list" component={ParaAlbumList} />
            <Route exact path="/profile/album/edit/:id" component={ParaAlbumEdit} />
            <Route exact path="/profile/album/edit/name/:id" component={ParaAlbumEditName} />
            <Route exact path="/profile/album/upload" component={ParaAlbumUpload} />
            <Route exact path="/profile/image/list/:id" component={ParaImageList} />
            <Route exact path="/profile/image/edit/:id" component={ParaImageEdit} />
            <Route exact path="/profile/image/upload/:id" component={ParaImageUpload} />
            <Route exact path="/profile/image/sort/:id" component={ParaSortImage} />
            <Route exact path="/admin/list" component={AdminList} />
            <Route exact path="/admin/event/list" component={EventList} />
            <Route exact path="/admin/event/edit/:id" component={EventEdit} />
            <Route exact path="/admin/event/upload" component={EventUpload} />
            <Route exact path="/admin/image/upload/:id" component={ImageUpload} />
            <Route exact path="/admin/image/list/:id" component={ImageList} />
            <Route exact path="/admin/image/edit/:id" component={ImageEdit} />
            <Route exact path="/admin/image/sort/:id" component={ImageSort} />
            <Route exact path="/admin/document/upload" component={DocumentUpload} />
            <Route exact path="/admin/document/list" component={DocumentList} />
            <Route exact path="/admin/document/sort" component={DocumentSort} />
            <Route exact path="/admin/document/edit/:id" component={DocumentEdit} />
            <Route exact path="/admin/album/list" component={AlbumList} />
            <Route exact path="/admin/album/edit/:id" component={AlbumEdit} />
            <Route exact path="/admin/album/edit/name/:id" component={AlbumEditName} />
            <Route exact path="/admin/album/sort" component={AlbumSort} />
            <Route exact path="/admin/album/upload" component={AlbumUpload} />
            <Route exact path="/admin/pages/list" component={PagesList} />
            <Route exact path="/admin/pages/edit/:stranka" component={PagesEdit} />
            <Route exact path="/password/forgot" component={ForgotPassword} />
            <Route exact path="/password/change/list" component={PasswordChangeList} />
            <Route exact path="/password/change/:id" component={ChangePassword} />
            <Route path="/password/reset" component={ResetPassword} />
            <Route exact path="/access/denied" component={AccessDenied} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </ApplicationProvider>
    </div>
  );
}

export default App;