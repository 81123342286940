import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { Link, useParams } from "react-router-dom";
import Navmenu from "../Navmenu";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../Pages/Loading";

const AlbumEdit = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const editorRef = useRef(null);
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const log = () => {
    if (editorRef.current) {
      response.description = editorRef.current.getContent();
      var data = JSON.stringify(response);
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/Album/description`,
          { payload: data },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .catch((err) => {
        })
        .then(() => {
          setDone(true);
        });
    }
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderSuccess() {
    if (done) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} fade="true" color="success" className="mt-3">
            Album upraveno
            <Button
              className="mx-3"
              size="sm"
              color="success"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <div className="container mt-3 border-radius shadow-lg p-1 bg-dark rounded">
            {renderSuccess()}
            <Button
              className="btn-primary mb-2"
              tag={Link}
              to={`/admin/album/list`}
            >
              Zpět na alba
            </Button>
            <Editor
              apiKey="ul6mdzl069gm6wnobufb6hvemuyx42jga41vk5v0g4i5uay9"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={response.description}
              init={{
                skin: "oxide-dark",
                content_css: "dark",
                mode: "none",
                selector: "textarea#image-tools",
                image_caption: true,
                height: 600,
                menubar: false,
                plugins: [
                  "advlist autolink lists table link charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help " +
                  "insertfile media pageembed template link anchor codesample",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                imagetools_toolbar:
                  "rotateleft rotateright | flipv fliph | editimage imageoptions",
                contextmenu: "link image table",
                menubar: "file edit view insert format tools table tc help",
                image_advtab: true,
                paste_data_images: true,
                file_picker_types: "image",
                /* and here's our custom image picker*/
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");
                  input.onchange = function () {
                    var file = this.files[0];

                    var reader = new FileReader();
                    reader.onload = function () {
                      var id = "blobid" + new Date().getTime();
                      var blobCache =
                        window.tinymce.activeEditor.editorUpload.blobCache;
                      var base64 = reader.result.split(",")[1];
                      var blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);

                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };

                  input.click();
                },
              }}
            />
            <Button className="btn btn-success mt-2" onClick={log}>
              Uložit
            </Button>
          </div>
        </>
      );
    }
  }
};

export default AlbumEdit;
