import React from 'react';
import { Route } from "react-router-dom";
import NavMenuAll from './NavMenuAll';
import Footer from './Footer';

const LayoutAll = ({ component: Component, layout: Layout, ...rest }) => {
  if (Layout === undefined) {
    Layout = (props) => <React.Fragment>{props.children}</React.Fragment>;
  }
  return (
    <>
      <NavMenuAll />
        <Route
          {...rest}
          render={(props) => (
            <Layout {...rest}>
              <Component {...props} />
            </Layout>
          )}
        />
      <Footer />
    </>
  );
}

export default LayoutAll;