import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Card, Col, Row, CardTitle, Button, Container } from "reactstrap";
import "../styles/style.css";
import Navmenu from "../layout/NavMenuAll";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";
import AccessDenied from "../Pages/AccessDenied";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import csLocale from "@fullcalendar/core/locales/cs";
import Loading from "../Pages/Loading";
import moment from 'moment-with-locales-es6';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfileList = () => {
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData/pro-cleny`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data.text);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/");
      });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Event/getEvents`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setEventList(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/");
      });
    setIsLoading(false);
  }, []);

  var eventListItem = [];

  eventList.map((item) => {
    eventListItem.push({ title: item.name, allDay: true, start: item.date });
  });

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const handleDateClick = (args) => {
    moment.locale("cs");
    setTitle(args.event._def.title);
    setDate(moment(`${args.event._instance.range.start}`).format('l'));
    handleClickOpen();
  };

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <div className="bg-white" style={{ height: "100vh", overflow: "auto"  }}>
            <Container>
              <p>
                <br></br>
              </p>
              <h1 className="text-dark display-3">Sekce pro členy</h1>
              <Row className="mt-3">
                <Col md className="mb-3">
                  <Card body className="border-radius shadow-lg rounded">
                    <FullCalendar
                      locale={csLocale}
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      events={eventListItem}
                      eventClick={handleDateClick}
                    />
                  </Card>
                </Col>
                <Col className="mb-3">
                  <Card body className="border-radius shadow-lg rounded">
                    <CardTitle tag="h1" className="text-center">
                      Informace pro členy
                    </CardTitle>
                    <hr />
                    <UnsafeComponent html={response} />
                  </Card>
                  <Card
                    body
                    className="border-radius shadow-lg rounded text-center mt-3"
                  >
                    <CardTitle tag="h2">Dokumenty</CardTitle>
                    <Button
                      color="primary"
                      tag={Link}
                      to="/profile/document/list"
                      outline
                    >
                      Otevřít
                    </Button>
                  </Card>
                  <Card
                    body
                    className="border-radius shadow-lg rounded text-center mt-3"
                  >
                    <CardTitle tag="h2">
                      Vytvořit album na fotky / videa
                    </CardTitle>
                    <Button
                      color="primary"
                      tag={Link}
                      to="/profile/album/list"
                      outline
                    >
                      Otevřít
                    </Button>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Row>
                  <Col className="m-0 p-0">
                    <p className="fs-5 text-start">{date}</p>
                  </Col>
                  <Col className="text-end m-0 p-0">
                    <Button
                      className="mb-2 col-4"
                      size="sm"
                      onClick={handleClose}
                      color="danger"
                      outline
                      autoFocus
                    >
                      <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </Button>
                  </Col>
                </Row>
                <Row className="mb-2 fs-3">{title}</Row>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      );
    }
  }
};

export default ProfileList;
