import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  Container,
  Button,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/style.css";
import Logo from "../assets/images/LOGO-paraklubW.svg";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";
import { Divide as Hamburger } from "hamburger-react";
import ScreenSizeDetector from "screen-size-detector";

const Navmenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const history = useHistory();
  const screen = new ScreenSizeDetector();
  const [screenW, setScreenW] = useState(false);

  useEffect(() => {
    if (screen.width > 990) {
      setScreenW(false);
    } else if (screen.width < 990) {
      setScreenW(true);
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
  }, [screen.width]);

  const logout = () => {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}/Account/logout`);
  };

  if (role === "ADMIN") {
    return (
      <Navbar className="navbar bg-dark navbar-expand-lg navbar-toggleable-lg navbar-dark sticky-top">
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img src={Logo} className="navbarSvgSmaller" />
          </NavbarBrand>
          {screenW ? (
            <Hamburger
              rounded
              duration={0.5}
              color="#ffffff"
              toggled={isOpen}
              toggle={setIsOpen}
            />
          ) : (
            ""
          )}
          <Collapse
            className="d-lg-inline-flex flex-lg-row-reverse bg-dark p-1"
            isOpen={isOpen}
            navbar
          >
            <ul className="navbar-nav d-flex align-middle flex-grow mr-auto mt-lg-0">
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt navbar-navi"
                  to="/"
                >
                  <Button className="btn btn-success" size="sm">HOME</Button>
                </NavLink>
              </NavItem>
              {window.innerWidth > 991 ? (
                <NavItem className="text-light navbar-navll  mt-1">|</NavItem>
              ) : (
                ""
              )}
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt navbar-navi"
                  to="/admin/list"
                >
                  <Button className="btn btn-primary" size="sm">Admin sekce</Button>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt navbar-navi"
                  to="/profile/list"
                >
                  <Button className="btn btn-primary" size="sm">Sekce pro členy</Button>
                </NavLink>
              </NavItem>
              {window.innerWidth > 991 ? (
                <NavItem className="text-light navbar-navll  mt-1">|</NavItem>
              ) : (
                ""
              )}
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt mt-1"
                  to="/admin/pages/list"
                >
                  Stránky
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt mt-1"
                  to="/admin/album/list"
                >
                  Album / Galerie
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt mt-1"
                  to="/admin/event/list"
                >
                  Kalendář / Akce
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt mt-1"
                  to="/admin/document/list"
                >
                  Dokumenty
                </NavLink>
              </NavItem>
              {window.innerWidth > 991 ? (
                <NavItem className="text-light navbar-navll mt-1">|</NavItem>
              ) : (
                ""
              )}
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt"
                  onClick={() => logout()}
                  to="/"
                >
                  <Button className="btn btn-warning" size="sm">Odhlásit se</Button>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  className="text-light navt"
                  to="/password/change/list"
                >
                  <Button className="btn btn-warning" size="sm">Změnit heslo</Button>
                </NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
};

export default Navmenu;
