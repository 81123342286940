import React, { useState, useContext, useCallback, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Container, Alert, Row, Col } from "reactstrap";
import Navmenu from "../Navmenu";
import axios from "axios";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../admin.css";
import toast, { Toaster } from "react-hot-toast";

function Previews() {
  const [files, setFiles] = useState([]);
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const { id } = useParams();
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [count, setCount] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const notify = (name) => toast.success(`${name} byl uložen!`);
  const notifyError = () =>
    toast.error(
      "Někde se stala chyba, zkuste to znovu nebo kontaktujte správce."
    );

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/count/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setCount(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setCount(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png,image/jpg,image/jpeg",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function uploadData() {
    files.map((file, index) => ((file.albumId = id), sendData(index)));
  }

  function sendData(index) {
    setUploading(true);
    const data = new FormData();
    data.append(`Image`, files[index]);
    data.append(`Name`, files[index].name);
    data.append("AlbumId", files[index].albumId);
    if (count === 0 && index === 0) {
      data.append("isMain", true);
    } else {
      data.append("isMain", false);
    }
    setDone(false);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/Images`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {})
      .catch((err) => {
        setError(true);
        notifyError();
      })
      .then(() => {
        setDone(true);
        setUploading(false);
        if(!error) {
          notify(files[index].name);
        }
      });
  }

  function renderButton() {
    if (files.length > 0) {
      return (
        <>
          <Button
            className="btn-success mt-1 mb-2"
            onClick={() => uploadData()}
          >
            {uploading ? (done) ? "Odesláno" : "Odesílám..." : "Uložit"}
          </Button>
          <Toaster position="bottom-right" reverseOrder={false} />
        </>
      );
    }
  }

  const images = files.map((file, index) => (
    <div key={file.id} className="mt-1 col-sm-12">
      <Row>
        <Col className="col-2">
          <img src={file.preview} style={{ width: "200px" }} alt="preview" />
        </Col>
        <Col className="col-4">
          <Button
            color="danger"
            refresh="true"
            size="sm"
            className="mx-3 col-1"
            onClick={() => {
              removeItem(index);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      </Row>
    </div>
  ));

  const removeItem = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }

  if (role === "ADMIN") {
    return (
      <>
        <div
          {...getRootProps()}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <input {...getInputProps()} />
          <p className="border-radius col-sm-6 shadow-lg p-3 mb-5 bg-dark rounded text-light text-center drag">
            <span>Nahraj obrázky </span>
          </p>
        </div>
        {renderButton()}
        <div>{images}</div>
      </>
    );
  }
}

const ImageUploader = () => {
  const { id } = useParams();
  return (
    <>
      <Navmenu />
      <Container className="text-center">
        <Button
          className="btn-primary mt-3 mx-3"
          tag={Link}
          to={`/admin/image/list/${id}`}
        >
          Na výpis obrázků
        </Button>
      </Container>
      <div className="d-flex flex-column mt-3 justify-content-center align-items-center">
        <Container>
          <Previews />
        </Container>
      </div>
    </>
  );
};

export default ImageUploader;
