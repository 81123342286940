import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import LayoutAll from "./layout/LayoutAll";
import "./styles/projects.css";
import "./styles/hover.css";
import Loading from "./Pages/Loading";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Row, Button, ButtonGroup, Col, Container } from "reactstrap";
import {
  faGreaterThan,
  faLessThan,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AlbumView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [image, setImage] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [tempIndex, setTempIndex] = useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/project/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/projects");
      })
      .finally(() => {});
    axios
      .get(`api/Images/getImages/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setImage(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setImage(null);
        }
        history.push("/gallery/list");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  function renderImages() {
    return (
      <Row>
        {image.map((item, index) => (
          <>
            <Col sm className="my-3" key={item.id}>
              <div
                class="content text-center border-radius shadow-sm rounded d-flex justify-content-center align-middle"
                onClick={() => {
                  handleClickOpen();
                  setTempIndex(index);
                }}
              >
                <div class="content-overlayIco"></div>
                <img
                  src={`data:image/jpeg;base64,${item.preview}`}
                  className="content-image"
                  alt="img"
                />
                <div class="content-detailsIco fadeIn-bottom">
                  <i class="yukiIco content-title"></i>
                </div>
              </div>
            </Col>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle>
                <p className="fs-5">{image[tempIndex].description}</p>
              </DialogTitle>
              <DialogContent>
                <img
                  className="content-imageModal"
                  src={`data:image/jpeg;base64,${image[tempIndex].preview}`}
                  alt="img"
                />
              </DialogContent>
              <DialogActions>
                <ButtonGroup>
                  <Button
                    color="transparent"
                    outline
                    onClick={() =>
                      tempIndex - 1 < 0
                        ? setTempIndex(0)
                        : setTempIndex(tempIndex - 1)
                    }
                  >
                    <FontAwesomeIcon icon={faLessThan}></FontAwesomeIcon>
                  </Button>
                  <Button
                    color="transparent"
                    outline
                    onClick={() =>
                      tempIndex + 1 > image.length - 1
                        ? setTempIndex(image.length - 1)
                        : setTempIndex(tempIndex + 1)
                    }
                  >
                    <FontAwesomeIcon icon={faGreaterThan}></FontAwesomeIcon>
                  </Button>
                </ButtonGroup>
                <Button
                  onClick={handleClose}
                  color="transparent"
                  outline
                  autoFocus
                >
                  <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ))}
      </Row>
    );
  }

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <LayoutAll>
          <section class="intro plain">
            <div class="inner-content container">
              <div class="row">
                <div class="heading">
                  <h1 class="hugHeading right">{response.name}</h1>
                </div>
              </div>
              <div class="row">
                <div class="description">
                  <p>
                    <br></br>
                  </p>
                  <p class="hug text-white">
                    <UnsafeComponent html={response.description} />
                  </p>
                  <br></br>
                  <p class="hug text-white"></p>
                </div>
              </div>
            </div>
          </section>
          <div className="bg-white">
            <Container>
              {renderImages()}
            </Container>
          </div>
        </LayoutAll>
      </>
    );
  }
};

export default AlbumView;
