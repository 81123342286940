import React, { useContext, useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Container, Button, Alert, Table, Col, Row } from "reactstrap";
import Navmenu from "../Navmenu";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTimes,
  faCheck,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Loading from "../../Pages/Loading";
import "../admin.css";

const ImageList = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const [indexItem, setIndexItem] = useState();
  const [item, setItem] = useState();
  const { id } = useParams();
  const onDismiss = () => setVisible(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Images/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderImages() {
    function renderCheck(check) {
      if (check) {
        return (
          <>
            <Button color="success" refresh="true" disabled={check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
      if (!check) {
        return (
          <>
            <Button color="secondary" refresh="true" disabled={!check}>
              <FontAwesomeIcon icon={faCheck} className="fs-6" />
            </Button>
          </>
        );
      }
    }
    const array = response.map((item, index) => {
      return (
        <tr key={item.id} className="text-white align-middle">
          <td>
            <img
              src={`data:image/jpeg;base64,${item.preview}`}
              className="imgSize"
            />
          </td>
          <td>{item.fileName}</td>
          <td>{item.description !== "" ? "ANO" : "NE"}</td>
          <td>{renderCheck(item.isMain)}</td>
          <td>{item.position}</td>
          <td>
            <Button
              color="danger"
              refresh="true"
              onClick={() => {
                handleClickOpen();
                setItem(item.id);
                setIndexItem(index);
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="fs-6" />
            </Button>
          </td>
          <td>
            <Button
              color="success"
              refresh="true"
              tag={Link}
              to={`/admin/image/edit/${item.id}`}
            >
              <FontAwesomeIcon icon={faPencilAlt} size="sm" className="fs-6" />
            </Button>
          </td>
        </tr>
      );
    });
    return array;
  }

  const removeItem = (index) => {
    response.splice(index, 1);
    setResponse([...response]);
  };

  function deleteProject() {
    setIsLoading(true);
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Images/delete/${item}`)
      .then(() => {
        removeItem(indexItem);
        setDone(true);
      })
      .catch((error) => {
        setError(true);
        setVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return response;
  }

  function renderSuccess() {
    if (done) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="success" className="mt-3">
            Obrázek odstraněn
            <Button
              className="mx-3"
              size="sm"
              color="success"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  function renderError() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Došlo k chybě! Zkuste to znovu nebo kontaktujte správce.
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <Container>
            {renderSuccess()}
            {renderError()}
            <Row>
              <Col>
                <Button
                  className="btn-success mt-3 mb-3 me-2"
                  tag={Link}
                  to={`/admin/image/upload/${id}`}
                >
                  Přidat obrázky do alba
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn-success mt-3"
                  tag={Link}
                  to={`/admin/image/sort/${id}`}
                >
                  Seřadit obrázky
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn-primary mt-3 me-2"
                  tag={Link}
                  to={`/admin/album/list`}
                >
                  Zpět na alba
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn-primary mt-3 mb-3"
                  tag={Link}
                  to={`/admin/album/edit/${id}`}
                >
                  Nastavení alba
                </Button>
              </Col>
            </Row>
            <Table
              responsive
              className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded"
            >
              <thead>
                <tr>
                  <th>Náhled</th>
                  <th>Název</th>
                  <th>Popis</th>
                  <th>Je hlavní v albu?</th>
                  <th>Pozice</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderImages()}</tbody>
            </Table>
          </Container>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Potvrďte, že chcete smazat tento obrázek. Po smazání obrázku už
                to nejde vzít zpět!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Zrušit
              </Button>
              <Button
                onClick={() => {
                  deleteProject();
                  handleClose();
                }}
                color="danger"
              >
                Potvrdit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
};

export default ImageList;
