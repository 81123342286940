import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Navmenu from "../Navmenu";
import {
  Button,
  ButtonGroup,
  Input,
  Row,
  Col,
  Container,
  Table,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { useAuthContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import Loading from "../../Pages/Loading";

const EventEdit = () => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [{ role }] = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [name, setName] = useState();
  const [date, setDate] = useState(false);
  const { id } = useParams();

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Event/getEvent/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        setResponse(res.data);
        setName(res.data.name);
        setDate(res.data.date);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally((res) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, []);

  function sendData() {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Event/editEvent`,
        {
          Id: response.id,
          Name: name,
          Date: date,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        history.push(`/admin/event/list`);
      })
      .catch((err) => {});
  }

  const handleChangeName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleChangeDes = (e) => {
    e.preventDefault();
    setDate(e.target.value);
  };

  function renderData() {
    return (
      <>
        <Card className="bg-dark text-light mt-3 border-radius shadow-lg bg-dark rounded p-3">
          <CardBody>
            <Label className="mt-2 mb-1">Název akce</Label>
            <Input
              className="bg-light"
              onChange={handleChangeName}
              value={name}
            />

            <Label className="mt-2 mb-1">Datum akce</Label>
            <Input
              type="date"
              className="bg-light"
              onChange={handleChangeDes}
              value={date}
            />
            <Button className="btn btn-success mt-3" onClick={() => sendData()}>
              Uložit
            </Button>
          </CardBody>
        </Card>
      </>
    );
  }

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <Container className="col-md-6">
            <div className="text-center">
              <Button
                className="btn-primary mt-3 me-3"
                tag={Link}
                to={`/admin/event/list`}
              >
                Zpět na výpis akcí
              </Button>
            </div>
            {renderData()}
          </Container>
        </>
      );
    }
  }
};

export default EventEdit;
