import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import Navmenu from "./Navmenu";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";
import AccessDenied from "../Pages/AccessDenied";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useHistory, useParams } from "react-router-dom";
import Loading from "../Pages/Loading";
import { Button } from "reactstrap";

const PagesEdit = () => {
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const editorRef = useRef(null);
  const history = useHistory();
  const { stranka } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
  }, []);

  const log = () => {
    if (editorRef.current) {
      response.text = editorRef.current.getContent();
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/PagesData`,
          {
            Id: response.id,
            Type: response.type,
            Text: response.text,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push("/admin/pages/list");
        })
        .catch((err) => {
        });
    }
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData/${stranka}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <div className="container mt-3 border-radius shadow-lg p-1 bg-dark rounded">
            <Button className="btn btn-primary mb-2" tag={Link} to="/admin/pages/list">Zpět na výpis stránek</Button>
            <Editor
              apiKey="ul6mdzl069gm6wnobufb6hvemuyx42jga41vk5v0g4i5uay9"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={response.text}
              init={{
                mode : "none",
                selector: "textarea#image-tools",
                image_caption: true,
                height: 600,
                menubar: false,
                plugins: [
                  "advlist autolink lists table link charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help | image" +
                  "insertfile media pageembed template link anchor codesample",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                imagetools_toolbar:
                  "rotateleft rotateright | flipv fliph | editimage imageoptions",
                contextmenu: "link image table",
                menubar: "file edit view insert format tools table tc help",
                image_advtab: true,
                paste_data_images: true,
                file_picker_types: "image",
              }}
            />
            <Button className="btn btn-success mt-2" onClick={log}>
              Uložit
            </Button>
          </div>
        </>
      );
    }
  }
};

export default PagesEdit;
