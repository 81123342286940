import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Navmenu from "../Navmenu";
import {
  Button,
  Input,
  Container,
  Table,
  Label,
} from "reactstrap";
import axios from "axios";
import { useAuthContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import Loading from "../../Pages/Loading";

const DocumentEdit = (props) => {
  const history = useHistory();
  const [{ accessToken }] = useAuthContext();
  const [{ role }] = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const { id } = useParams();

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Document/getDocument/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        setResponse(res.data);
        setName(res.data.fileName);
        setDescription(res.data.description);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally((res) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, []);

  function sendData() {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/Document/editDocument`,
        {
          Id: response.id,
          FileName: name,
          Description: description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(() => {
        history.push(`/admin/document/list`);
      })
      .catch((err) => {
      });
  }

  const handleChangeName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleChangeDes = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  function renderData() {
    return (
      <>
        <Table className="bg-dark text-light mt-3 border-radius shadow-lg bg-dark rounded">
          <tbody>
            <tr>
              <td>
                <div className="">
                  <Label className="mb-2">Jméno souboru</Label>
                  <Input
                    className="bg-light"
                    onChange={handleChangeName}
                    value={name}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="">
                  <Label className="mb-2">Popis souboru</Label>
                  <Input
                    className="bg-light"
                    onChange={handleChangeDes}
                    value={description}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Button className="btn btn-success" onClick={() => sendData()}>
          Uložit
        </Button>
      </>
    );
  }

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <Navmenu />
          <Container className="col-sm-4">
            <div className="text-center">
              <Button
                className="btn-primary mt-3 mx-3"
                tag={Link}
                to={`/admin/document/list`}
              >
                Zpět na výpis dokumentů
              </Button>
            </div>
            <div className="text-center ">
              {renderData()}
            </div>
          </Container>
        </>
      );
    }
  }
};

export default DocumentEdit;
