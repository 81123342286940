import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  CardTitle,
  Button,
  Container,
  CardBody,
  Badge
} from "reactstrap";
import "./admin.css";
import Navmenu from "./Navmenu";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";
import AccessDenied from "../Pages/AccessDenied";

const AdminList = () => {
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [count, setCount] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/count`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setCount(response.data)
      });
  }, []);

  function renderCount() {
    if(count === 0) {
      return (
        <>
        </>
      )
    } else if (count > 0) {
      return (
        <>
          <span class="badge position-absolute top-0 start-100 translate-middle bg-danger">{count}</span>
        </>
      )
    }
  }

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    return (
      <>
        <Navmenu />
        <Container className="text-center">
          <h1 className="text-light display-1 mt-3">Admin sekce</h1>
          <Row className="mt-3">
            <Col className="my-1">
              <Card
                body
                inverse
                className="h-100"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">Stránky</CardTitle>
                <CardBody className="d-flex flex-column">
                  <Button tag={Link} to="/admin/pages/list" className="mt-auto">
                    Upravit
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col className="my-1">
              <Card
                body
                inverse
                className="h-100"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">Album / Galerie</CardTitle>
                <CardBody className="d-flex flex-column">
                  <Button tag={Link} to="/admin/album/list" className="mt-auto position-relative">
                    Upravit / Schválit {renderCount()}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col className="my-1">
              <Card
                body
                inverse
                className="h-100"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">Kalendář / Akce</CardTitle>
                <CardBody className="d-flex flex-column">
                  <Button tag={Link} to="/admin/event/list" className="mt-auto">
                    Upravit
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col className="my-1">
              <Card
                body
                inverse
                className="h-100 d-flex flex-column"
                style={{
                  backgroundColor: "#212529",
                  borderColor: "#212529",
                }}
              >
                <CardTitle tag="h2">Dokumenty</CardTitle>
                <CardBody className="d-flex flex-column">
                  <Button tag={Link} to="/admin/document/list" className="mt-auto">
                    Upravit
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default AdminList;
