import React, { useCallback, useEffect, useState, useContext } from "react";
import { Button, Container, Table } from "reactstrap";
import axios from "axios";
import Navmenu from "./Navmenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Loading from "../Pages/Loading";
import { ApplicationContext } from "../../providers/ApplicationProvider";
import AccessDenied from "../Pages/AccessDenied";

const PagesList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [{ accessToken }] = useContext(ApplicationContext);

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderProjects() {
    const array = response.map((item, index) => {
      return (
        <tr key={item.id} className="text-white align-middle">
          <td>{item.type}</td>
          <td>
            <Button
              color="success"
              refresh="true"
              tag={Link}
              to={`/admin/pages/edit/${item.type}`}
            >
              <FontAwesomeIcon icon={faFileSignature} className="fs-6" />
            </Button>
          </td>
        </tr>
      );
    });
    return array;
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (response) {
      return (
        <>
          <Navmenu />
          <Container className="col-md-4">
            <Table className="bg-dark text-light mt-3 border-radius shadow-lg p-1 bg-dark rounded">
              <thead>
                <tr>
                  <th>Stránka</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderProjects()}</tbody>
            </Table>
          </Container>
        </>
      );
    }
  } else {
    return (
      <>
        <Loading />
      </>
    );
  }
};

export default PagesList;