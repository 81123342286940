import React, { useState, useContext } from "react";
import {
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CardBody,
  Alert,
  Container,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useFormik, FormikProvider } from "formik";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Heslo musí být vyplněno";
  }
  if (!values.newpassword) {
    errors.newpassword = "Heslo musí být vyplněno";
  }
  else if (!values.newpassword.length < 6) {
    errors.newpassword =
      "Heslo musí být vyplněno a musí obsahovat alespoň 6 znaků";
  }
  if (!values.repassword) {
    errors.repassword = "Potvrďte heslo";
  }
  else if (values.repassword !== values.newpassword) {
    errors.repassword = "Hesla se musí shodovat";
    return errors;
  }
};

export default function ChangePassword() {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const { id } = useParams();

  function renderAlertEmail() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Někde se stala chyba, opravdu jste zadal správně heslo?
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      id: "",
      password: "",
      newpassword: "",
      repassword: "",
    },
    validate: validate,
    onSubmit: (values) => {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/Account/changePassword`,
          {
            id: id,
            oldPassword: values.password,
            newPassword: values.newpassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then(() => {
          history.push(`/para/login`);
        })
        .catch(() => {
          setError(true);
        });
    },
  });
  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    return (
      <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <Container>
          {renderAlertEmail()}
          <FormikProvider value={formik}>
            <Card className="border-radius col-sm-6 zarovnat shadow-lg p-3 mb-5 bg-dark rounded">
              <CardBody className="bg-dark text-light">
                <div className="d-flex align-items-start">
                  <div className="font-weight-bold">
                    <small className="text-white-70 d-block font-size-xl mb-1 text-uppercase">
                      Zapomenuté heslo
                    </small>
                    <span className="font-size-xxl mt-1"></span>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    className="btn-dark mt-2 mb-2"
                    tag={Link}
                    to="/password/change/list"
                  >
                    Zpět na výběr účtů
                  </Button>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup className="m-2">
                    <Label for="password">Staré heslo</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Staré heslo"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      invalid={Boolean(formik.errors.password)}
                      valid={formik.touched.password}
                    />
                    {formik.errors.password ? (
                      <FormFeedback invalid>
                        {formik.errors.password}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="newpassword">Nové Heslo</Label>
                    <Input
                      type="password"
                      name="newpassword"
                      id="newpassword"
                      placeholder="Nové heslo"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newpassword}
                      invalid={Boolean(formik.errors.newpassword)}
                      valid={formik.touched.newpassword}
                    />
                    {formik.errors.newpassword ? (
                      <FormFeedback invalid>
                        {formik.errors.newpassword}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="m-2">
                    <Label for="repassword">Opakujte heslo</Label>
                    <Input
                      type="password"
                      name="repassword"
                      id="repassword"
                      placeholder="Heslo"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.repassword}
                      invalid={Boolean(formik.errors.repassword)}
                      valid={formik.touched.repassword}
                    />
                    {formik.errors.repassword ? (
                      <FormFeedback invalid>
                        {formik.errors.repassword}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <div>
                    <Button type="submit" className="m-2" color="success">
                      Změnit heslo
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </FormikProvider>
        </Container>
      </div>
    );
  }
}
