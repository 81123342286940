import React, { useContext, useState, useCallback, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Button,
  Alert,
  Card,
  CardTitle,
  Row,
  Col,
  CardBody,
} from "reactstrap";
import Navmenu from "../layout/NavMenuAll";
import { ApplicationContext } from "../../providers/ApplicationProvider";
import AccessDenied from "../Pages/AccessDenied";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Pages/Loading";
import { Link } from "react-router-dom";
import "./admin.css";
import "../styles/style.css";

const DocumentParaList = () => {
  const [{ accessToken }] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(true);
  const [item, setItem] = useState();
  const onDismiss = () => setVisible(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Document/getDocuments`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte]);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  function download(name, doc) {
    var sampleArr = base64ToArrayBuffer(doc);
    saveByteArray(name, sampleArr);
  }

  function renderImages() {
    var array;
    if (response.length === 0) {
      return (
        <>
          <div className="display-3 d-flex flex-column min-vh-100 justify-content-center align-items-center">
            Zatím zde nejsou žádné dokumenty{" "}
            <Button
              color="warning"
              className="fs-4 mt-2"
              tag={Link}
              to="/profile/list"
            >
              Zpět
            </Button>
          </div>
        </>
      );
    } else {
      array = response.map((item, index) => {
        return (
          <>
            <Col sm="4" className="my-2">
              <Card
                key={item.id}
                body
                className="border-radius shadow-lg rounded text-center h-100"
              >
                <CardTitle className="fs-5">
                  <strong>{item.description}</strong>
                </CardTitle>
                <hr></hr>
                <CardBody className="p-1 m-1">{item.fileName}</CardBody>
                <Button
                  color="primary"
                  refresh="true"
                  outline
                  onClick={() => download(item.fileName, item.fileData)}
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="sm"
                    className="fs-6"
                  />
                </Button>
              </Card>
            </Col>
          </>
        );
      });
    }
    return array;
  }

  function renderError() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Došlo k chybě! Zkuste to znovu nebo kontaktujte správce.
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    if (isLoading) {
      return (
        <>
          <Loading />
        </>
      );
    } else {
      return (
        <>
          <div className="bg-light" style={{ height: "100%" }}>
            <Navmenu />
            <Container>
              {renderError()}
              {response.length !== 0 ? (
                <>
                  <h1 className="display-3 mt-3 text-center">Dokumenty</h1>
                  <Row className="mx-1">
                    <Button
                      size="sm"
                      className="btn-primary col-md-2 mt-4"
                      tag={Link}
                      to="/profile/list"
                    >
                      Zpět na stránku pro členy
                    </Button>
                  </Row>
                </>
              ) : (
                ""
              )}
              <Row className="mt-3">{renderImages()}</Row>
            </Container>
          </div>
        </>
      );
    }
  }
};

export default DocumentParaList;
