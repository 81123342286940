import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "./layout/Layout";
import "./styles/style.css";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  CardText,
  CardImg,
  Button,
} from "reactstrap";
import axios from "axios";
import Loading from "./Pages/Loading";
import image1 from "./assets/images/background.jpg";
import tandem from "./assets/images/tandem.jpg";
import propagace from "./assets/images/propagace.jpg";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const [responseImage, setResponseImage] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [aktuality, setAktuality] = useState();

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData/terminy`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data.text);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/getToHome`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponseImage(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        }
        history.push("/");
      });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData/aktuality`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setAktuality(response.data.text);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        }
        history.push("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderProjects() {
    const array = responseImage.map((item) => {
      if (item.picture == null) {
        return (
          <>
            <Col sm className="my-3" key={item.id}>
              <div className="content text-center border-radius shadow-sm rounded d-flex justify-content-center align-middle">
                <Link to={`/album/view/${item.albumId}`}>
                  <div className="content-overlay"></div>
                  <FontAwesomeIcon
                    icon={faYoutube}
                    color="red"
                    className="content-imageY"
                  />
                  <div className="content-details fadeIn-bottom">
                    <h4 className="content-title">{item.name}</h4>
                  </div>
                </Link>
              </div>
            </Col>
          </>
        );
      } else {
        return (
          <>
            <Col md className="my-3" key={item.id}>
              <div className="content border-radius shadow-sm rounded d-flex justify-content-center align-middle">
                <Link to={`/album/view/${item.albumId}`}>
                  <div className="content-overlay"></div>
                  <img
                    src={`data:image/jpeg;base64,${item.picture}`}
                    className="content-image"
                    alt="img"
                  />
                  <div className="content-details fadeIn-bottom">
                    <h4 className="content-title">{item.name}</h4>
                  </div>
                </Link>
              </div>
            </Col>
          </>
        );
      }
    });
    return array;
  }

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Layout>
          <div className="appColor">
            <div>
              <div class="caption pb-10 font-weight-bold">
                <h1 class="display-2 text-caption text-dark">PARAKLUB LIBEREC</h1>
              </div>
            </div>
            <svg class="arrows captionArrow">
              <path class="a1" d="M0 0 L30 32 L60 0"></path>
              <path class="a2" d="M0 20 L30 52 L60 20"></path>
            </svg>
          </div>
          <div className="bg-white">
            <div className="container">
              <Row>
                <Col className="text-center">
                  <p>
                    <br></br>
                  </p>
                  <UnsafeComponent html={aktuality} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg="4">
                  <Card body className="my-3 border-radius shadow-sm rounded">
                    <CardImg
                      className="homepage-image"
                      alt="Card image cap"
                      src={image1}
                      top
                    />
                    <CardBody className="d-flex flex-column">
                      <CardTitle tag="h5" className="text-dark">
                        Parašutistický výcvik
                      </CardTitle>
                      <CardText className="text-dark">
                        Základní výcvik provádíme na školních padácích typu
                        křídlo M330 - STUDENT.
                      </CardText>
                      <Button
                        color="primary"
                        outline
                        className="mt-auto"
                        tag={Link}
                        to="/zakladni-vycvik"
                      >
                        Více
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card body className="my-3 border-radius shadow-sm rounded">
                    <CardImg
                      className="homepage-image"
                      alt="Card image cap"
                      src={propagace}
                      top
                    />
                    <CardBody className="d-flex flex-column">
                      <CardTitle tag="h5" className="text-dark">
                        Propagační seskoky
                      </CardTitle>
                      <CardText className="text-dark">
                        Provedeme seskok na padácích u příležitosti sportovních,
                        kulturních či společenských akcí.
                      </CardText>
                      <Button
                        color="primary"
                        outline
                        className="mt-auto"
                        tag={Link}
                        to="/kontakt/info"
                      >
                        Více
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card body className="my-3 border-radius shadow-sm rounded">
                    <CardImg
                      className="homepage-image"
                      alt="Card image cap"
                      src={tandem}
                      top
                    />
                    <CardBody className="d-flex flex-column">
                      <CardTitle tag="h5" className="text-dark">
                        Tandemové seskoky
                      </CardTitle>
                      <CardText className="text-dark">
                        Tandemové seskoky ze 3 km. S videem a fotkami.
                      </CardText>
                      <a
                        href="http://www.tandemyliberec.cz/tandemy"
                        target="_blank"
                        className="btn btn-outline-primary mt-auto"
                      >
                        Více
                      </a>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <UnsafeComponent html={response} />
                  <p>
                    <br></br>
                  </p>
                  <p>
                    <br></br>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <div className="showcase3">
            <div className="captionProject font-weight-bold">
              <h1 className="display-2 text-white align-middle">GALERIE</h1>
            </div>
          </div>
          <div className="bg-white">
            <Container>
              <Row>{renderProjects()}</Row>
            </Container>
          </div>
        </Layout>
      </>
    );
  }
};

export default Home;
