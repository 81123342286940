import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Button, Container, Row, Col } from "reactstrap";
import axios from "axios";
import Loading from "./Loading";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";

const AccessDenied = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <Container className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center">
          <Card className="bg-dark shadow-lg p-3 mb-5 bg-dark rounded col-sm-6">
            <CardBody>
              <CardTitle tag="h1" className="text-white">
                Na tuto stránku nemáte dostatečná práva!
              </CardTitle>
              <Row>
                <Col>
                <Button className="mt-5 mb-3" color="danger" tag={Link} to="/">
                  Zpět na hlavní stránku
                </Button>
                </Col>
                <Col>
                <Button
                  className="mt-5 mb-3 mx-3 text-light"
                  color="success"
                  tag={Link}
                  to="/para/login"
                >
                  Přihlásit se
                </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
};

export default AccessDenied;
