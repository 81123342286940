import React from "react";
import Logo from "../assets/images/jumperLoading.svg";
import "./style.css";

const Loading = () => {
  return (
    <>
      <section className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center bg-white">
        <img src={Logo} className="yukiIcoLoading mb-3" />
      </section>
    </>
  );
};
export default Loading;
