import React, { useState, useContext, useCallback, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Container, Alert, Row, Col } from "reactstrap";
import Navmenu from "../../layout/NavMenuAll";
import axios from "axios";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../admin.css";
import "../../styles/style.css";

function Previews() {
  const [files, setFiles] = useState([]);
  const [{ accessToken }] = useContext(ApplicationContext);
  const { id } = useParams();
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [count, setCount] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/count/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        setCount(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setCount(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function uploadData() {
    files.map((file, index) => ((file.albumId = id), sendData(index)));
  }

  function sendData(index) {
    const data = new FormData();
    data.append(`Image`, files[index]);
    data.append(`Name`, files[index].name);
    data.append("AlbumId", files[index].albumId);
    if (count === 0 && index === 0) {
      data.append("isMain", true);
    } else {
      data.append("isMain", false);
    }
    setDone(false);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/Images`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {})
      .catch((err) => {})
      .finally(() => {
        setDone(true);
      });
  }

  function renderAlert() {
    if (done) {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Alert isOpen={visible} color="success" className="mt-3">
              Obrázky byly nahrány!
              <Button
                className="mx-3"
                size="sm"
                color="success"
                onClick={onDismiss}
              >
                <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
              </Button>
            </Alert>
          </div>
        </>
      );
    }
  }

  function renderButton() {
    if (files.length > 0) {
      return (
        <>
          <Button className="btn-success mt-1" onClick={() => uploadData()}>
            Uložit
          </Button>
        </>
      );
    }
  }

  const removeItem = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const images = files.map((file, index) => (
    <div key={file.name} className="mt-1 col-sm-4">
      <Row>
        <Col className="col-6">
          <img src={file.preview} style={{ width: "200px" }} alt="preview" />
        </Col>
        <Col className="col-6">
          <Button
            color="danger"
            refresh="true"
            size="sm"
            className="mx-3 col-2"
            onClick={() => {
              removeItem(index);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Col>
      </Row>
    </div>
  ));

  if (!accessToken) {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (accessToken) {
    return (
      <>
        {renderAlert()}
        <div
          {...getRootProps()}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <input {...getInputProps()} />
          <p className="border-radius col-sm-6 shadow-lg p-3 mb-5 bg-dark rounded text-light text-center">
            Nahraj obrázky
          </p>
        </div>
        {renderButton()}
        <div>{images}</div>
      </>
    );
  }
}

const ParaImageUploader = () => {
  const { id } = useParams();
  return (
    <>
      <Navmenu />
      <div className="bg-white" style={{ height: "100vh" }}>
        <Container className="text-center">
          <Button
            className="btn-primary mt-3 mx-3"
            tag={Link}
            to={`/profile/image/list/${id}`}
          >
            Na výpis obrázků
          </Button>
        </Container>
        <div className="d-flex flex-column mt-3 justify-content-center align-items-center">
          <Container>
            <Previews />
          </Container>
        </div>
      </div>
    </>
  );
};

export default ParaImageUploader;
