import React, { useState } from "react";
import {
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  CardBody,
  Alert,
  Container,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useFormik, FormikProvider } from "formik";
import queryString from "query-string";

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Heslo musí být vyplněno";
  }
  if (!values.repassword) {
    errors.repassword = "Potvrďte heslo";
  }
  if (values.repassword !== values.password) {
    errors.repassword = "Hesla se musí shodovat";
    return errors;
  }
};

export default function ResetPassword(props) {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  function renderAlertEmail() {
    if (error) {
      return (
        <div className="d-flex justify-content-between">
          <Alert isOpen={visible} color="danger" className="mt-3">
            Někde se stala chyba! Opravdu jste zadal sprvánou adresu?
            <Button
              className="mx-3"
              size="sm"
              color="danger"
              onClick={onDismiss}
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </Button>
          </Alert>
        </div>
      );
    }
  }

  const url = queryString.parse(props.location.search);

  const formik = useFormik({
    initialValues: {
      email: "",
      token: "",
      password: "",
      repassword: "",
    },
    validate: validate,
    onSubmit: (values) => {
      setError(false);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/Account/ResetPassword`, {
          Email: values.email,
          Token: url.token,
          Password: values.password,
        })
        .then(() => {
          history.push("/para/login");
        })
        .catch(() => {
          setError(true);
        });
    },
  });
  return (
    <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <Container>
        {renderAlertEmail()}
        <FormikProvider value={formik}>
          <Card className="border-radius col-sm-6 zarovnat shadow-lg p-3 mb-5 bg-dark rounded">
            <CardBody className="bg-dark text-light">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-70 d-block font-size-xl mb-1 text-uppercase">
                    Obnovení hesla
                  </small>
                  <span className="font-size-xxl mt-1"></span>
                </div>
                <div className="ml-auto">
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={faSignInAlt}
                      className="font-size-xl"
                    />
                  </div>
                </div>
              </div>
              <Form onSubmit={formik.handleSubmit}>
                <FormGroup className="m-2">
                  <Label for="email">E-mail</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="jan.novak@gmail.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    invalid={Boolean(formik.errors.email)}
                    valid={formik.touched.email}
                  />
                </FormGroup>
                <FormGroup className="m-2">
                  <Label for="password">Nové Heslo</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Nové heslo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    invalid={Boolean(formik.errors.password)}
                    valid={formik.touched.password}
                  />
                  {formik.errors.password ? (
                    <FormFeedback invalid>
                      {formik.errors.password}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup className="m-2">
                  <Label for="repassword">Opakujte heslo</Label>
                  <Input
                    type="password"
                    name="repassword"
                    id="repassword"
                    placeholder="Heslo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.repassword}
                    invalid={Boolean(formik.errors.repassword)}
                    valid={formik.touched.repassword}
                  />
                  {formik.errors.repassword ? (
                    <FormFeedback invalid>
                      {formik.errors.repassword}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <div>
                  <Button type="submit" className="m-2" color="success">
                    Restartovat heslo
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </FormikProvider>
      </Container>
    </div>
  );
}
