import React, { useState, useCallback, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { Row, Col, Container } from "reactstrap";
import LayoutAll from "./layout/LayoutAll";
import Loading from "./Pages/Loading";
import "./styles/style.css";
import "./styles/hover.css";
import "./styles/projects.css";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Gallery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);

  const history = useHistory();

  const closeLightbox = () => {
    this.state.open = true;
  };

  const FetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Album/getAll`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
        history.push("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  function renderProjects() {
    const array = response.map((item) => {
      if (item.picture == null) {
        return (
          <>
            <Col sm className="my-3" key={item.id}>
              <div className="content text-center border-radius shadow-sm rounded d-flex justify-content-center align-middle">
                <Link to={`/album/view/${item.albumId}`}>
                  <div className="content-overlay"></div>
                  <FontAwesomeIcon
                    icon={faYoutube}
                    color="red"
                    className="content-imageY"
                  />
                  <div className="content-details fadeIn-bottom">
                    <h4 className="content-title">{item.name}</h4>
                  </div>
                </Link>
              </div>
            </Col>
          </>
        );
      } else {
        return (
          <>
            <Col sm className="my-3" key={item.id}>
              <div className="content border-radius shadow-sm rounded d-flex justify-content-center align-middle">
                <Link to={`/album/view/${item.albumId}`}>
                  <div className="content-overlay"></div>
                  <img
                    src={`data:image/jpeg;base64,${item.picture}`}
                    className="content-image"
                    alt="img"
                  />
                  <div className="content-details fadeIn-bottom">
                    <h4 className="content-title">{item.name}</h4>
                  </div>
                </Link>
              </div>
            </Col>
          </>
        );
      }
    });
    return array;
  }

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  } else {
    return (
      <>
        <LayoutAll>
          <div className="bg-white">
            <section className="bg-dark">
              <p>
                <br></br>
              </p>
              <h1 className="text-light text-center display-3 mt-1 gallerySection">GALERIE</h1>
              <p>
                <br></br>
              </p>
            </section>
            <Container>
              <Row>{renderProjects()}</Row>
            </Container>
          </div>
        </LayoutAll>
      </>
    );
  }
};

export default Gallery;
