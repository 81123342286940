import React, { useState, useEffect, useContext } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/style.css";
import "../styles/hover.css";
import Logo from "../assets/images/LOGO-paraklubn.svg";
import {
  ApplicationContext,
  SET_ACCESS_TOKEN,
} from "../../providers/ApplicationProvider";
import { Divide as Hamburger } from "hamburger-react";
import ScreenSizeDetector from 'screen-size-detector';

const NavMenuAll = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [{ accessToken }, dispatch] = useContext(ApplicationContext);
  const screen = new ScreenSizeDetector();
  const [screenW, setScreenW] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY > 45) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  useEffect(() => {
    if(screen.width > 990) {
      setScreenW(false);
    } else if (screen.width < 990) {
      setScreenW(true);
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/Account/getToken`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.data.accessToken,
        });
      });
  }, [screen.width]);

  const logout = () => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/Account/logout`)
      .then(() => {
        window.location.reload();
      });
  };

  function renderLogout() {
    if (accessToken) {
      return (
        <>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className="text-dark navt decoration link"
              onClick={() => logout()}
              tag={Link}
              to="/"
            >
              ODHLÁSIT SE
            </NavLink>
          </NavItem>
        </>
      );
    }
  }

  return (
    <Navbar
      className={`navbar bg-light navbar-expand-lg navbar-toggleable-lg navbar-light sticky-top borderNav`}
    >
      <Container>
        <NavbarBrand tag={Link} to="/">
          <img
            src={Logo}
            className={`${colorChange ? "navbarSvgSmaller" : "navbarsvg"}`}
            alt="img"
          />
        </NavbarBrand>
        {screenW ? <Hamburger rounded color="#000000" duration={0.5} toggled={isOpen} toggle={setIsOpen}/> : ""}
        <Collapse
          className="d-lg-inline-flex flex-lg-row-reverse p-1"
          isOpen={isOpen}
          navbar
        >
          <ul className={`navbar-nav flex-grow mr-auto mt-lg-0`}>
            <NavItem>
              <NavLink className="text-dark navt" to="/" tag={Link}>
                HOME
              </NavLink>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle className="text-dark" caret nav>
                  INFORMACE
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/osvedceni">
                    Osvědčení školy
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/historie">
                    Historie klubu
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle className="text-dark" caret nav>
                  PARAŠKOLA
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/zakladni-vycvik">
                    Základní výcvik
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/pokracovaci-vycvik">
                    Sportovní pokračovací výcvik
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem tag={Link} to="/prihlaska/form">
                    Přihláška / Termíny
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-dark navt" to="/galerie/list">
                GALERIE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} className="text-dark navt" to="/kontakt/info">
                KONTAKT
              </NavLink>
            </NavItem>
            {window.innerWidth > 991 ? (
              <NavItem className="text-dark navbar-navll">|</NavItem>
            ) : (
              ""
            )}
            <NavItem>
              <NavLink
                tag={Link}
                className="text-dark navt decoration"
                to="/para/login"
              >
                PRO ČLENY
              </NavLink>
            </NavItem>
            {renderLogout()}
          </ul>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavMenuAll;
