import React, { useState, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import LayoutAll from "./layout/LayoutAll";
import { useFormik, FormikProvider } from "formik";
import { useHistory, useParams, Link } from "react-router-dom";
import axios from "axios";
import "./styles/style.css";
import "./styles/aboutme.css";
import "./styles/hover.css";
import "./styles/contact.css";
import toast, { Toaster } from "react-hot-toast";
import QRcode from "./assets/images/QR_code.png";

const Application = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { history } = useHistory();
  const { stranka } = useParams();

  const notifyInfo = () => {
    toast(
      "Děkujeme za odeslanou přihlášku, právě nám skočila do e-mailu.\n\nBudeme se ji věnovat co nejdříve a pošleme Vám další informace o výcviku.\n\nV případě nejasností nás kontaktujte přes e-mail nebo na telefonu viz kontakty.",
      {
        duration: 60000,
        icon: "🪂"
      }
    );
  };

  const notify = () =>
    toast.success("Vaše přihláška byla odeslána!", { duration: 60000 });

  const notifyError = () =>
    toast.error("Někde se stala chyba, zkuste to znovu nebo nám napište.");

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  const FetchData = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/PagesData/terminy`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setResponse(response.data.text);
      })
      .catch((error) => {
        if (error.response) {
          setError({
            status: error.response.status,
            text: error.response.statusText,
          });
        } else {
          setError({ status: 0, text: "Neznámá chyba" });
          setResponse(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [stranka]);

  useEffect(() => {
    FetchData();
  }, [FetchData]);

  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = " ";
    }
    if (!values.birthday) {
      errors.birthday = " ";
    }
    if (!values.no) {
      errors.no = " ";
    }
    if (!values.email) {
      errors.email = " ";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Neplatná e-mailová adresa";
    }
    if (!values.telNumber) {
      errors.telNumber = " ";
    } else if (
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(values.telNumber)
    ) {
      errors.telNumber = "Neplatné telefonní číslo";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      no: "",
      street: "",
      city: "",
      psc: "",
      birthday: "",
      telNumber: "",
      text: "",
    },
    validate: validate,
    onSubmit: (values) => {
      var data = JSON.stringify(values);
      setError(false);
      setDone(false);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/PagesData/sendEmail`,
          {
            payload: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setDone(true);
          notify();
          notifyInfo();
          formik.resetForm();
        });
    },
  });

  return (
    <>
      <LayoutAll>
        <div className="bg-light">
          <section className="introAll plain min-vh-100">
            <div className="inner-content container">
              <Row>
                <Col md className="mt-3 me-3">
                  <h1 className="display-6 text-dark mb-2">
                    PODEJTE SI PŘIHLÁŠKU
                  </h1>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <FormGroup className="mt-2">
                        <Label for="fullName" className="text-dark">
                          Jméno a příjmení
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary fs-4"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          name="fullName"
                          id="fullName"
                          placeholder="Jan Novák"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.fullName}
                          invalid={Boolean(formik.errors.fullName)}
                          valid={formik.touched.fullName}
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="email" className="text-dark">
                          E-mail{" "}
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary fs-4"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="example@email.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          invalid={Boolean(formik.errors.email)}
                          valid={formik.touched.email}
                        />
                        {formik.errors.email ? (
                          <FormFeedback invalid>
                            {formik.errors.email}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="telNumber" className="text-dark">
                          Telefonní číslo{" "}
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary fs-4"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          name="telNumber"
                          id="telNumber"
                          placeholder="+420 777 888 999"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.telNumber}
                          invalid={Boolean(formik.errors.telNumber)}
                          valid={formik.touched.telNumber}
                        />
                        {formik.errors.telNumber ? (
                          <FormFeedback invalid>
                            {formik.errors.telNumber}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="birthday" className="text-dark">
                          Datum narození
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary fs-4"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          name="birthday"
                          id="birthday"
                          type="date"
                          placeholder="DD.MM.YYYY"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.birthday}
                          invalid={Boolean(formik.errors.birthday)}
                          valid={formik.touched.birthday}
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="no" className="text-dark">
                          Číslo výcviku
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary fs-4"
                          >
                            *
                          </p>
                        </Label>
                        <Input
                          name="no"
                          id="no"
                          placeholder="ZV č. 1"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.no}
                          invalid={Boolean(formik.errors.no)}
                          valid={formik.touched.no}
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="street" className="text-dark">
                          Ulice
                        </Label>
                        <Input
                          name="street"
                          id="street"
                          placeholder="Ostašovská 641/67"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.street}
                          invalid={Boolean(formik.errors.street)}
                          valid={formik.touched.street}
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="city" className="text-dark">
                          Město
                        </Label>
                        <Input
                          name="city"
                          id="city"
                          placeholder="Liberec"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          invalid={Boolean(formik.errors.city)}
                          valid={formik.touched.city}
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="psc" className="text-dark">
                          PSČ
                        </Label>
                        <Input
                          name="psc"
                          id="psc"
                          placeholder="460 01"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.psc}
                          invalid={Boolean(formik.errors.psc)}
                          valid={formik.touched.psc}
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <Label for="text" className="text-dark">
                          Vaše zpráva
                        </Label>
                        <Input
                          type="textarea"
                          name="text"
                          id="text"
                          placeholder="Vaše zpráva..."
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.text}
                          invalid={Boolean(formik.errors.text)}
                          valid={formik.touched.text}
                        />
                      </FormGroup>
                      <div>
                        <p className="text-dark mt-3 sizeReq">
                          <p
                            style={{ display: "inline-block" }}
                            className="text-primary fs-4"
                          >
                            *
                          </p>{" "}
                          - Povinné
                        </p>

                        <Button
                          type="submit"
                          className={`${
                            formik.isValid ? "" : "disabled"
                          } mt-3 mb-3`}
                          color="success"
                        >
                          Odeslat
                        </Button>
                        {window.innerWidth > 576 ? <Toaster position="top-center" reverseOrder={false} /> : <Toaster position="bottom-center" reverseOrder={false} />}
                      </div>
                    </Form>
                  </FormikProvider>
                </Col>
                <Col className="pt-3">
                  <UnsafeComponent html={response} />
                  <h2>Platba přes QR kód</h2>
                  <p>QR kód pro platby na účet Paraklub Liberec z.s.</p>
                  <p><b>Do poznámky vždy uvádějte účel platby.</b></p>
                  <img src={QRcode} className="qr_code"/>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </LayoutAll>
    </>
  );
};

export default Application;
