import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Table, Row } from "reactstrap";
import Navmenu from "../Navmenu";
import axios from "axios";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AccessDenied from "../../Pages/AccessDenied";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../admin.css";
import toast, { Toaster } from "react-hot-toast";

function DocumentUploader() {
  const [files, setFiles] = useState([]);
  const [{ accessToken }] = useContext(ApplicationContext);
  const [{ role }] = useContext(ApplicationContext);
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [uploading, setUploading] = useState(false);

  const notify = (name) => toast.success(`${name} byl uložen!`);
  const notifyError = () => toast.error("Někde se stala chyba, zkuste to znovu nebo kontaktujte správce.");


  const { getRootProps, getInputProps } = useDropzone({
    accept: ".doc,.docx,application/pdf,.xlsx,.pptx,.odt,.xls",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function uploadData() {
    files.map((file, index) => sendData(index));
  }

  function sendData(index) {
    setUploading(true);
    const data = new FormData();
    var reg = /(\.[^.]+)$/gi;
    var name = files[index].name;
    var ext = name.match(reg);

    data.append(`FileData`, files[index]);
    data.append(`FileName`, files[index].name);
    data.append(`Extension`, ext);
    setDone(false);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/Document`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(() => {})
      .catch((err) => {
        setDone(false);
        notifyError();
      })
      .then(() => {
        setDone(true);
        setUploading(false);
        notify(files[index].name);
      });
  }

  function renderButton() {
    if (files.length > 0) {
      return (
        <>
          <Button className="btn-success mt-1" onClick={() => uploadData()}>
            {uploading ? (done) ? "Odesláno" : "Odesílám..." : "Uložit"}
          </Button>
          <Toaster position="bottom-right" reverseOrder={false} />
        </>
      );
    }
  }

  const images = files.map((file, index) => (
    <div key={file.id} className="mt-1 col-sm-12">
      <Row className="d-flex flex-row-reverse">
        <p className="text-light w-75 mt-1" style={{display: "inline-block"}}>{file.name}</p>
        <Button
          color="danger"
          refresh="true"
          size="sm"
          className="mx-3 col-1"
          onClick={() => {
            removeItem(index);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Row>
    </div>
  ));

  const removeItem = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  if (!accessToken || role !== "ADMIN") {
    return (
      <>
        <AccessDenied />
      </>
    );
  }
  if (role === "ADMIN") {
    return (
      <>
        <div
          {...getRootProps()}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <input {...getInputProps()} />
          <p className="border-radius col-sm-6 shadow-lg p-3 mb-5 bg-dark rounded text-light text-center drag">
            Nahraj dokumenty
          </p>
        </div>
        {renderButton()}
        <Table className="bg-dark text-light border-radius shadow-lg mt-3 bg-dark rounded">
          <tbody>
            <div>{images}</div>
          </tbody>
        </Table>
      </>
    );
  }
}

const DocumentUpload = () => {
  return (
    <>
      <Navmenu />
      <Container className="text-center">
        <Button
          className="btn-primary mt-3 mx-3"
          tag={Link}
          to={`/admin/document/list`}
        >
          Na výpis dokumentů
        </Button>
      </Container>
      <div className="d-flex flex-column mt-3 justify-content-center align-items-center">
        <Container className="col-sm-4">
          <DocumentUploader />
        </Container>
      </div>
    </>
  );
};

export default DocumentUpload;
