import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import "../styles/style.css";
import { Col, Container, Row } from "reactstrap";
import Logo from "../assets/images/LOGO-paraklub.svg";
import LKLB from "../assets/images/logo.jpg";
import Tandemy from "../assets/images/Tandemy LBC.png";

const Footer = () => {
  return (
    <footer className="footer bg-light text-dark p-3">
      <Container>
        <Row className="d-flex justify-content-around">
          <Col lg="8" className="ml-3 mt-3">
            <Row className="mt-2">
              <Col>
                <p className="fs-3 text-dark">Adresa</p>
              </Col>
              <Col>
                <p className="fs-3 text-dark">Kontakt</p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <a className="tel text-dark">Paraklub Liberec z.s.</a>
              </Col>
              <Col>
                <a className="tel text-dark" href="tel:+420 602 410 760">
                  Tel: +420 602 410 760
                </a>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <a className="tel text-dark">Ostašovská 641/67</a>
              </Col>
              <Col>
                <a
                  className="tel text-dark"
                  href="mailto:paraklublbc@seznam.cz"
                >
                  E-mail: paraklublbc@seznam.cz
                </a>
                <a
                  className="tel text-dark"
                  href="mailto:rene.faber01@gmail.com"
                >
                  , rene.faber01@gmail.com
                </a>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <a className="tel text-dark">460 01, Liberec 11</a>
              </Col>
              <Col>
                <a className="tel text-dark">Číslo účtu: 980637399/0800</a>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <a className="tel text-dark">IČ: 46744231, DIČ: CZ46744231</a>
              </Col>
              <Col>
                <a className="tel text-dark">Datová schránka: 73ctgix</a>
              </Col>
            </Row>
          </Col>
          <Col lg="4" className="mt-2 text-center">
            <img src={Logo} className="footersvg mb-3 mt-3" alt="img"/>
            <Row>
              <a className="text-dark">
                {new Date().getFullYear()} © Paraklub Liberec
              </a>
            </Row>
            <Row className="wrapper">
              <a
                href="https://www.facebook.com/paraklublbc/"
                target="_blank"
                className="btn btn-sm m-1 fbico"
              >
                <FontAwesomeIcon className="icon" size="2x" icon={faFacebookF} />
              </a>
            </Row>
          </Col>
        </Row>
        <Row><p><br></br></p></Row>
        <Row>
          <Col md="2">
            <p className="fs-6">Partneři: </p>
            <a href="https://www.aeroklubliberec.cz/" target="_blank"><img src={LKLB} className="footerLogo" alt="img"/></a>
          </Col>
          <Col md="2">
            <br></br>
            <a href="http://www.tandemyliberec.cz/objednavky" target="_blank"><img src={Tandemy} className="footerLogo" alt="img"/></a>
          </Col>
        </Row>
        <Row className="text-center fs-6 mt-3">
          <a
            href="https://tomas-kulhavy.cz/"
            target="_blank"
            className="cby mt-3"
          >
            Made with ❤️ by Tomáš Kulhavý
          </a>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
